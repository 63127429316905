var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{ref:"purchase-invoices-reports-table",attrs:{"resource":_vm.$data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICE_REPORTS,"resourceStore":'purchaseInvoicesReports',"columns":_vm.columns,"filters":_vm.filters,"totals-configuration":_vm.totalsConfiguration,"loading":_vm.loading},on:{"filter-button-click":function($event){_vm.filtersVisible = !_vm.filtersVisible},"row-clicked":function($event){return _vm.$router.push({
        name: 'viewPurchaseInvoice',
        params: { id: $event.id },
      })},"on-clear-active-filters":_vm.handleClearListFilters,"data-loaded":function($event){return _vm.$emit('data-loaded', $event)}},scopedSlots:_vm._u([{key:"cell(job)",fn:function(ref){
      var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value ? value.code : ""))])]}},{key:"cell(provider)",fn:function(ref){
      var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value.name))])]}},{key:"cell(invoice_date)",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(value)))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.expiration_date),expression:"item.expiration_date"}],staticClass:"font-small text-light"},[_vm._v(" vence "+_vm._s(_vm._f("formatDate")(item.expiration_date))+" ")])]}},{key:"cell(expiration_date)",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(
          item.status.name === 'Pendiente de pago' ||
          item.status.name === 'Pago parcial'
        ),expression:"\n          item.status.name === 'Pendiente de pago' ||\n          item.status.name === 'Pago parcial'\n        "}],class:(_vm.isDueDateExpired(value) ? 'text-danger' : 'text-primary') +
          ' text-uppercase'},[_vm._v(" "+_vm._s(_vm.getDueDateDaysText(value))+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('StatusBadge',{attrs:{"status":data.item.status.name,"text":data.item.status.name === 'Pago parcial' ? 
          ("Pago parcial " + (data.item.percent !== 0 && data.item.percent !== 100 ? ((data.item.percent) + " % ") : '')) 
        : null}}),(data.item.send_payment_document && data.item.send_payment_document === 1)?_c('StatusBadge',{staticClass:"mt-1",attrs:{"status":'Envio doc. pago',"text":'Envio doc. pago',"variant":'light-success'}}):_vm._e()]}},{key:"cell(total_gross)",fn:function(ref){
        var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numberToLocalString")(value))+" €")])]}},{key:"cell(total_invoice)",fn:function(ref){
        var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numberToLocalString")(value))+" €")])]}},{key:"cell(total_pending_payment)",fn:function(ref){
        var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numberToLocalString")(value))+" €")])]}},{key:"cell(total_liquid)",fn:function(ref){
        var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numberToLocalString")(value))+" €")])]}},{key:"cell(total_retention)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("numberToLocalString")(data.item.total_retention))+" €")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(data.item.retention_expiration),expression:"data.item.retention_expiration"}],staticClass:"font-small text-light"},[_vm._v(" vence "+_vm._s(_vm._f("formatDate")(data.item.retention_expiration))+" ")])]}}])}),_c('PurchaseInvoicesReportsListFilters',{ref:"purchase-invoices-reports-list-filters",on:{"filters-submit":function($event){return _vm.$store.commit('purchaseInvoiceReports/setFilters', $event)}},model:{value:(_vm.filtersVisible),callback:function ($$v) {_vm.filtersVisible=$$v},expression:"filtersVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }