<template>
  <BaseCard
    :footer-visible="false"
    :caption-visible="false"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">
        Informe facturas pendientes de pago
      </span>
    </template>
    <PurchaseInvoiceReportsTable />
  </BaseCard>
</template>


<script>
import PurchaseInvoiceReportsTable from '@/components/purchase-invoices-reports/table/PurchaseInvoicesReportsTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { 
    BaseCard, 
    PurchaseInvoiceReportsTable
  },
}
</script>
